
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import moment, { Moment } from 'moment';

@Component
export default class VDateRangeTimePicker extends Vue {
  @Prop({ type: Number, default: 340 }) public width!: number;
  @Prop({ type: String, default: '' }) public label!: string;
  @Prop({ type: Boolean }) public disabled!: boolean;
  @Prop({ type: [Object, String], default: () => null }) public value!: any;
  @Prop({ type: Boolean, default: true }) public required!: boolean;
  @Prop({ type: [Array, Function] }) public allowedDates!: any[] | (() => void);
  @Prop({ type: [Array, Function] }) public allowedHours!: any[] | (() => void);
  @Prop({ type: [Array, Function] }) public allowedMinutes!: any[] | (() => void);

  public range: { from: string; to: string } = {
    from: '',
    to: '',
  };

  public activeTabFrom: number = 0;
  public dateFrom: string = '';
  public timeFrom: string = '';
  public isVisibleFrom: boolean = false;

  public activeTabTo: number = 0;
  public dateTo: string = '';
  public timeTo: string = '';
  public isVisibleTo: boolean = false;

  get dateSelected() {
    return !this.dateFrom || this.dateFrom.length === 0;
  }

  get dateSelectedTo() {
    return !this.dateTo || this.dateTo.length === 0;
  }

  get formattedDateTime() {
    if (this.dateFrom && this.timeFrom) {
      return `${this.dateFrom} ${this.timeFrom}`;
    }
    if (this.dateFrom) {
      return `${this.dateFrom}`;
    }

    return '';
  }

  get formattedDateTimeTo() {
    if (this.dateTo && this.timeTo) {
      return `${this.dateTo} ${this.timeTo}`;
    }

    if (this.dateTo) {
      return `${this.dateTo}`;
    }

    return '';
  }

  get fromRules() {
    if (this.required) {
      return 'required|date_before:@to';
    }

    return '';
  }

  get toRules() {
    if (this.required) {
      return 'required';
    }

    return '';
  }

  public mounted() {
    this.init();
  }

  public init() {
    if (this.value) {
      if (moment(this.value.from).isValid()) {
        this.dateFrom = moment(this.value.from).format('YYYY-MM-DD');
        this.timeFrom = moment(this.value.from).format('HH:mm');
      }
      if (moment(this.value.to).isValid()) {
        this.dateTo = moment(this.value.to).format('YYYY-MM-DD');
        this.timeTo = moment(this.value.to).format('HH:mm');
      }
    }
  }

  public cancel() {
    this.isVisibleFrom = false;
  }

  public cancelTo() {
    this.isVisibleTo = false;
  }

  public showTimePicker() {
    this.timeFrom = '00:00';
    // this.isVisibleFrom = false;
    this.activeTabFrom = 1;
    this.onRangeChange();
  }

  public showTimePickerTo() {
    this.timeTo = '23:59';
    // this.isVisibleTo = false;
    this.activeTabTo = 1;
    this.onRangeChange();
  }

  public saveTo() {
    this.isVisibleTo = false;
    this.activeTabTo = 0;
    this.onRangeChange();
    return moment(this.formattedDateTimeTo).toISOString();
  }

  public save() {
    this.isVisibleFrom = false;
    this.activeTabFrom = 0;
    this.onRangeChange();
    return moment(this.formattedDateTime).toISOString();
  }

  public clearDateRangeTo() {
    this.isVisibleFrom = false;
    this.dateTo = '';
    this.timeTo = '';
    this.activeTabTo = 0;
    return '';
  }

  public clearDateRangeFrom() {
    this.isVisibleFrom = false;
    this.dateFrom = '';
    this.timeFrom = '';
    this.activeTabFrom = 0;
    return '';
  }

  @Emit('input')
  public onRangeChange() {
    return { from: this.formattedDateTime, to: this.formattedDateTimeTo };
  }
}
