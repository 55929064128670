
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class VDateMonthPicker extends Vue {
  @Prop({ type: Boolean, default: true }) public required!: boolean;
  @Prop({ type: [Array, Function] }) public allowedDates!: any[] | (() => void);

  public month: string = '';
  public monthMenu: boolean = false;

  get rules() {
    if (this.required) {
      return 'required';
    }

    return '';
  }

  @Watch('month')
  @Emit('input')
  public onMonthChange() {
    return this.month;
  }
}
