
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import moment from 'moment';

@Component
export default class VDateRangePicker extends Vue {
  @Prop({ type: Boolean, default: true }) public required!: boolean;
  @Prop({ type: [Object, String], default: () => null }) public value!: { from: string; to: string } | string;
  @Prop({ type: [Array, Function] }) public allowedDates!: any[] | (() => void);

  public from: string = '';
  public fromMenu: boolean = false;
  public to: string = '';
  public toMenu: boolean = false;

  get range() {
    if (this.from && this.from.length > 0 && this.to && this.to.length > 0) {
      return {
        from: this.from,
        to: this.to,
      };
    }

    return null;
  }

  public mounted() {
    this.init();
  }

  public init() {
    if (typeof this.value === 'string') {
      return;
    }

    if (this.value && this.value.from && this.value.to && this.value.from !== '' && this.value.to !== '') {
      this.from = moment(this.value.from).format('YYYY-MM-DD');
      this.to = moment(this.value.to).format('YYYY-MM-DD');
    }
  }

  get fromRules() {
    if (this.required) {
      return 'required|date_before:@to';
    }

    return '';
  }

  get toRules() {
    if (this.required) {
      return 'required';
    }

    return '';
  }

  @Watch('value')
  public onValueChange(val: Range, oldVal: Range) {
    if (val !== oldVal) {
      this.init();
    }
  }

  @Watch('range')
  @Emit('input')
  public onRangeChange() {
    return this.range;
  }
}
